.footerSection {
  background-color: rgb(20, 25, 89,0.94);
  display: flex;
  padding: 15px 40px;
  justify-content: center;
  @media only screen and (max-width: 768px) {
    flex-direction: column;
    padding: 15px 20px;
  }
}

.quickLinks {
  display: block;
  color: white;
  flex-basis: 30%;
  margin-top: 1%;
  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
  }
  h4 {
    padding-left: 17px;
    text-decoration: underline;
    @media only screen and (max-width: 768px) {
      padding-left: 0px;
      margin-bottom:8px;
    }
  }
  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
    @media only screen and (max-width: 768px) {
      padding-left: 0px;
      margin-left: 0px;
    }
  }
  ul li {
    position: relative;
    padding: 6px 0px;
    @media only screen and (max-width: 768px) {
      padding-left: 0px;
    }
  }
  li a {
    padding: 0 20px;
    text-decoration: none;
    background-color: transparent;
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    word-wrap: break-word;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      padding: 0px;
    }
  }
}

.contactUs {
  display: block;
  color: white;
  flex-basis: 30%;
  margin-top: 1%;
  h4 {
    text-decoration: underline;
    @media only screen and (max-width: 768px) {
      margin-bottom:8px;
    }
  }
  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
  }
  ul li {
    position: relative;
    padding: 10px 0px;
    i {
      padding-right: 10px;
    }
  }
  li a {
    padding: 0 20px;
    text-decoration: none;
    background-color: transparent;
    color: white;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    word-wrap: break-word;
    cursor: pointer !important;
  }
  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
  }
}
.logo {
  flex-basis: 30%;
  padding-top: 20px;
  img {
    width: 150px;
    height: 180px;
  }
  a {
    cursor: pointer;
  }
  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
    img {
      width: 90px;
      height: 100px;
    }
  }
}

.copyrightContainer {
  background-color: rgb(20, 25, 89,0.89);
  border-top: 1px solid whitesmoke;
  padding: 5px 20px 5px 20px;
  color: white;
  display: flex;
  justify-content: center;
  p {
    display: inline;
    margin: 6px 0px 0px 0px;
  }
  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
  }
  li {
    display: inline-block;
    position: relative;
    padding: 5px 0px;
    i {
      padding-right: 20px;
      color: white;
      font-size: 18px;
    }
  }
}

.activeLink {
  color: #ffc235 !important;
}

.addressLink.addressLink {
  padding: 0px;
  text-decoration: underline;
  background-color: transparent;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  cursor: pointer;
}
