.growingMoment {
  background-image: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url("../../../../images/GrowingMoment/tp_06.jpg");
  background-position: center center;
  background-repeat: no-repeat;
  background-attachment: fixed;
  -webkit-background-attachment: fixed;
  -moz-background-attachment: fixed;
  -o-background-attachment: fixed;
  background-color: rgba(20, 25, 93, 0.84);
  opacity: 0.9;
  height: 400px;
  width: 100%;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: 100% 100%;
  p {
    color: rgba(255, 255, 255, 2.6);
    letter-spacing: 1px;
    font-size: 17px;
  }
  @media only screen and (max-width: 768px) {
    background-size: cover;
    -webkit-background-attachment: none;
    -moz-background-attachment: none;
    -o-background-attachment: none;
    background-attachment: fixed;
  }
}
.visitTrafficParkBtn {
  position: relative;
  background-color: rgb(20, 25, 89, 0.89);
  display: block;
  margin-top: 30px;
  border-radius: 27px;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  word-wrap: break-word;
  padding: 15px 50px 15px 35px;
  margin: 0 auto;
  cursor: pointer;
  line-height: 20px;
}

.arrowRight {
  position: absolute;
  right: 19px;
  top: 20px;
}

.growingMomentSection {
  text-align: center;
  flex-basis: 50%;
  padding-left: 15px;
  padding-right: 15px;
  h2 {
    color: #fff;
    font-weight: 500;
    font-size: 34px;
    line-height: 34px;
  }
  p {
    text-align: justify;
    letter-spacing: 1px;
  }
}

@media only screen and (max-width: 768px) {
  .growingMomentSection {
    flex-basis: 100%;
  }
}
