.aboutUs {
  display: flex;
  background-color: #f4f4f4;
  padding: 10px 30px 10px 30px;
  /* border: 1px solid lightgrey; */
  @media (max-width: 768px) {
    flex-direction: column;
    padding: 30px 10px 10px 10px;
  }

  .infoSection {
    flex-basis: 46%;
    margin-top: 10px;
    @media (max-width: 768px) {
      flex-basis: 100%;
    }
  }
  .videoSection {
    flex-basis: 50%;
    margin-top: 4%;
    @media (max-width: 768px) {
      flex-basis: 100%;
      padding-left: 0px;
    }
  }
  h5 {
    color: rgba(10, 10, 10, 0.6);
    text-align: left;
    font-size: 20px;
    /* font-weight: 700; */
    margin: 0px;
    text-transform: uppercase;
  }
  h2 {
    font-size: 28px;
    color: rgb(20, 25, 89,0.89);
    text-align: left;
    text-transform: uppercase;
    margin-bottom: 5px;
  }
  p {
    font-size: 17px;
    font-weight: 600;
    color: rgba(10, 10, 10, 0.9);
    text-align: justify;
  }
}
.dividerLine {
  margin-top: 10px;
  width: 350px;
  height: 3px;
  margin-bottom: 10px;
  transform: scale(1, 1);
  visibility: visible;
  background-color: rgb(20, 25, 89,0.89);
  @media (max-width: 768px) {
    width: 100%;
  }
}

.aboutUsDetail {
  margin-top: 90px;
  padding-left: 24px;
  flex-basis: 50%;
  @media (max-width: 768px) {
    margin-top: 0px;
    padding-left: 5px;
  }
}
