.infoWrapper {
  width: 100%;
  padding: 0 0 40px 0;
  background-color: #f4f4f4;
  @media (max-width: 768px) {
    margin: 25px 0px 0px 0px;
    padding: 0 10px 20px 10px;
    width: 96%;
  }
}
