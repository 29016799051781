.aboutUsView {
  p {
    color: rgb(10, 10, 10, 0.9);
    font-weight: 600;
  }
}

.aboutUsView > h2 {
  color: rgb(20, 25, 89, 0.89);
  margin: 0px;
  text-transform: uppercase;
  text-align: center;
}
.aboutUsInfo {
  display: flex;
  margin-top: 3%;
  padding: 0px 20px 40px 20px;
  text-align: justify;
  @media (max-width: 768px) {
    flex-direction: column;
  }
  .aboutUsImage {
    flex-basis: 49%;
    margin-right: 49px;
    @media (max-width: 768px) {
      flex-basis: 100%;
      margin-right: 0px;
    }
  }
  div {
    flex-basis: 49%;
    @media (max-width: 768px) {
      flex-basis: 100%;
    }
  }
  img {
    height: auto;
    width: 100%;
  }
}

.dividerLine {
  margin-top: 8px;
  width: 250px;
  height: 3px;
  transform: scale(1, 1);
  visibility: visible;
  background-color: rgb(20, 25, 89, 0.89);
}

.welcomeContainer h2 {
  margin-bottom: 5px;
  margin-top: 0px;
  color: rgb(20, 25, 89, 0.89);
}

.aboutUsHeadLine {
  background-color: #f2f2f2;
  height: 100px;
  h2 {
    color: rgb(20, 25, 89, 0.89);
    margin: 0px;
    text-align: center;
    height: 100px;
    position: relative;
    top: 30%;
  }
}

.specialThanks {
  float: left;
}
.owners {
  color: rgb(20, 25, 89, 0.89);
  text-decoration: underline;
}

.welcomeContainer li {
  color: rgb(10, 10, 10, 0.9);
  padding: 5px 0px 5px 0px;
  font-weight: 600;
}

.welcomeContainer ul {
  margin: 0px;
  padding-left: 10px;
}
