.dividerLine {
  margin: 10px auto 25px auto;
  width: 250px;
  height: 3px;
  transform: scale(1, 1);
  visibility: visible;
  background-color: rgb(20, 25, 89,0.89);
}

.feedbackContainer {
  margin: 0px auto;
  width: 70%;
  @media only screen and (max-width: 700px) {
    width: 90% !important;
  }
}

.feedbackSection {
  background-color: #f4f4f4;
  padding-bottom: 60px;
  h3 {
    font-size: 17px;
    font-weight: 500;
    color: rgba(10, 10, 10, 0.9);
    text-align: center;
  }
  h2 {
    font-size: 22px;
    color: rgba(20, 25, 89,0.89);
    line-height: 45px;
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 0px;
    text-align: center;
    margin-top: 10px;
  }
}

.feedbackText {
  background-color: rgb(20, 25, 89,0.89);
  border-radius: 10px;
  min-height: 141px;
  letter-spacing: 1px;
  min-height: 130px;
  max-height: 250px;
  color: white;
  margin: 0px;
  padding: 10px 10px 10px 10px;
  text-align: justify;
}

.feedbackInfo {
  position: relative;
  margin-bottom: 20px;
  width: 90% !important;
  @media only screen and (max-width: 700px) {
    width: 100% !important;
  }
  .reviewerName {
    font-size: 17px;
    font-weight: 500;
    color: rgba(10, 10, 10, 0.9);
    width: 100%;
    word-wrap: break-word;
    margin-top: 26px;
    text-align: center;
    @media only screen and (max-width: 700px) {
      font-size: 16px;
    }
  }
}

.bottomArrow {
  content: "";
  margin: 0 auto;
  width: 0;
  height: 0;
  border-top: 22px solid rgb(20, 25, 89,0.89);
  border-left: 20px solid transparent;
  border-right: 20px solid transparent;
}
