.quizContainer {
  padding: 0px 0px 30px 0px;
}

.dividerLine {
  margin-top: 8px;
  width: 250px;
  height: 3px;
  transform: scale(1, 1);
  visibility: visible;
  background-color: rgb(20, 25, 89,0.89);
  text-align: center;
}

.quizHeadLine {
  color: rgb(20, 25, 89,0.89);
  background-color: #f2f2f2;
  height: 64px;
  h2 {
    color: rgb(20, 25, 89,0.89);
    margin: 0px;
    text-align: center;
    height: 100px;
    position: relative;
    top: 30%;
  }
}

.quizQuestion {
  margin: 0 auto;
  text-align: center;
  width: 70%;
  @media only screen and (max-width: 768px) {
    width: 80%;
  }
  p {
    color: rgb(20, 25, 89,0.89);
    font-size: 22px;
  }
}

.option {
  flex-basis: 46%;
  display: flex;
  background-color: white;
  box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);
  border: 1px solid rgb(20, 25, 89,0.89);
  padding: 20px 35px 20px 20px;
  margin: 2%;
  cursor: pointer;
  position: relative;
  color: rgb(20, 25, 89,0.89);
  letter-spacing: 1px;
  font-weight: 500;
  text-align: justify;
  font-size: 15px;
  font-family: redhatdisplay;
  height: auto;
  .correctIcon {
    font-size: 21px;
    color: green;
    position: absolute;
    right: 6px;
    top: 16px;
  }
  .wrongIcon {
    font-size: 22px;
    color: red;
    position: absolute;
    right: 6px;
    top: 22px;
  }
  img {
    padding: 0px 5px 0px 5px;
  }
  @media only screen and (max-width: 768px) {
    flex-basis: 100%;
  }
}

.correctAnswer {
  border: 2px solid green;
}

.wrongAnswer {
  border: 2px solid red;
}

.optionContainer {
  display: flex;
  flex-wrap: wrap;
  margin: 10px auto;
  text-align: center;
}

.nextBtn {
  position: relative;
  font-family: redhatdisplay;
  background-color: rgb(20, 25, 89,0.89);
  display: block;
  margin-top: 60px;
  border-radius: 27px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 2px;
  word-wrap: break-word;
  padding: 15px;
  margin: 10px 10px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  width: 170px;
}
.retakeBtn{
  position: relative;
  font-family: redhatdisplay;
  background-color: rgb(20, 25, 89,0.89);
  display: block;
  margin-top: 60px;
  border-radius: 27px;
  color: white;
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  line-height: 24px;
  letter-spacing: 2px;
  word-wrap: break-word;
  padding: 12px;
  margin: 25px 10px 25px 10px;
  text-align: center;
  cursor: pointer;
  display: inline-block;
  width: 160px;
  left: 45%;
}
.arrowRight {
  position: absolute;
  right: 19px;
  top: 20px;
}

.buttonContainer {
  text-align: center;
}

.correctLabel {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin: 30px 0px;
  color: rgb(20, 25, 89,0.89);
  i {
    padding-left: 10px;
    color: green;
  }
}

.inCorrectLabel {
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  margin: 30px 0px;
  color: rgb(20, 25, 89,0.89);
  i {
    padding-left: 10px;
    color: red;
  }
}

.quizStartContainer {
  text-align: center;
  font-size: 22px;
  color: rgb(20, 25, 89,0.89);
  padding: 30px 0px;
  img {
    height: 120px;
    width: 120px;
  }
}

.startQuizBtn {
  position: relative;
  background-color: rgb(20, 25, 89,0.89);
  display: block;
  border-radius: 27px;
  color: white;
  font-size: 14px;
  text-transform: uppercase;
  letter-spacing: 2px;
  word-wrap: break-word;
  padding: 15px 25px 15px 25px;
  margin: 23px auto 0px auto;
  cursor: pointer;
  line-height: 20px;
}

.quizScore {
  color: rgb(20, 25, 89,0.89);
  font-size: 20px;
  font-weight: 600;
  text-align: center;
  margin:20px 0px 10px 0px;
  @media only screen and (max-width: 768px) {
    margin:20px 0px 10px 0px;
  }
}
