@font-face {
  font-family: "redhatdisplay";
  src: url("./fonts/RedHatDisplay-VariableFont_wght.ttf") format("woff");
}

body {
  font-family: redhatdisplay;
  margin: 0px;
  padding: 0px;
}
