header {
  position: relative;
  left: 0;
  width: 100%;
  z-index: 99;
  background-color: transparent;
  top: 0;
  a {
    cursor: pointer;
  }
}

.siteLogo {
  display: flex;
  img {
    height: 90px;
    width: 80px;
    @media (max-width: 768px) {
      height: 90px;
      width: 80px;
    }
  }
}

.headerContainer {
  background-color: rgb(20, 25, 89,0.94);
  padding: 0px 90px;
  display: flex;
  justify-content: space-between;
  align-items: center !important;
  @media (max-width: 768px) {
    padding: 0px 30px;
  }
}
.desktopMenu {
  display: block;
  ul {
    margin: 0px;
    padding: 0px;
    list-style-type: none;
  }
  ul li {
    display: inline-block;
    position: relative;
    cursor: pointer;
  }
  li a {
    padding: 0 20px;
    text-decoration: none;
    background-color: transparent;
    color: white;
    text-transform: uppercase;
    font-size: 16px;
    line-height: 24px;
    word-wrap: break-word;
  }
  @media (max-width: 768px) {
    display: none;
  }
}

.hamburgerIcon {
  display: none;
  color: white;
  font-size: 22px;
}

@media only screen and (max-width: 768px) {
  .hamburgerIcon {
    display: block;
  }
}

.hamburgerMenu {
  background-color: rgb(20, 25, 89,0.89);
  display: none;
  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  li {
    padding: 14px 14px;
    position: relative;
  }
  li::before {
    background-color: rgba(0, 0, 0, 0.3);
    content: "";
    display: block;
    position: absolute;
    top: -1px;
    width: 100%;
    left: 0;
    height: 1px;
  }
  li a {
    color: white;
    padding: 14px 16px;
    text-decoration: none;
    font-size: 17px;
    display: block;
    text-transform: uppercase;
    border-bottom-color: #ffc235;
  }
  ul {
    margin: 0px;
  }
  @media (max-width: 768px) {
    display: block;
  }
}

.activeLink {
  color: #ffc235 !important;
}

.bookingBanner {
  background-color: #ffc235;
  height: 30px;
  color: rgb(20, 25, 89,0.89);
  padding: 2px 0px 2px 0px;
  text-align: center;

  a {
    color: rgb(20, 25, 89,0.89);
  }
  .bannerText {
    position: relative;
    top: 7px;
    letter-spacing: 1px;
    @media screen and (max-width: 768px) {
      animation: none;
      left: 5px;
    }
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
}
